




















































import { Component, Vue, Prop } from 'vue-property-decorator'
import MultiSelect, { MultiSelectOption } from 'client-website-ts-library/components/MultiSelect.vue'
import { ListingFilter } from 'client-website-ts-library/filters'
import { ListingCategory, ListingStatus, MethodOfSale, WebsiteLevel } from 'client-website-ts-library/types'
import { API, Config } from 'client-website-ts-library/services'
import NavigationLink from '@/components/Common/NavigationLink.vue'

@Component({
  components: {
    MultiSelect,
    NavigationLink,
  },
})
export default class ListingSearch extends Vue {
  @Prop({ default: 'for-sale' })
  private readonly methodOfSale!: string

  private mos: string = this.methodOfSale;

  private filter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    SearchLevel: WebsiteLevel.Office,
    SearchGuid: Config.Website.Settings!.WebsiteId,
    PageSize: 9,
  });

  private suburbs: MultiSelectOption[] = [];

  private selectedSuburbs: MultiSelectOption[] = [];

  private beds = 0;

  private baths = 0;

  mounted() {
    this.updateFilter()
  }

  // @Watch('methodOfSale')
  updateMos() {
    this.mos = this.methodOfSale
  }

  private updateSuburbsDebounce: number | undefined = undefined;

  updateSuburbs() {
    clearTimeout(this.updateSuburbsDebounce)

    this.updateSuburbsDebounce = window.setTimeout(() => {
      const tmpFilter = new ListingFilter(this.filter)

      tmpFilter.Suburbs = []

      API.Listings.GetSuburbs(tmpFilter).then((suburbs) => {
        this.suburbs = suburbs.map((suburb) => ({ Value: suburb, Label: suburb }))
      })
    }, 2000)
  }

  // @Watch('mos')
  // @Watch('beds')
  // @Watch('baths')
  updateFilter() {
    switch (this.mos) {
      case 'buy':
        this.filter.Categories = [
          ListingCategory.ResidentialSale,
          ListingCategory.ResidentialLand,
          ListingCategory.Rural,
        ]
        break
      case 'rent':
        this.filter.Categories = [
          ListingCategory.ResidentialRental,
        ]
        break
      default:
        this.filter.Categories = []
        break
    }

    this.updateSuburbs()

    this.filter.MinBedrooms = this.beds
    this.filter.MinBathrooms = this.baths

    this.filter.Suburbs = this.selectedSuburbs.map((s) => s.Value)
    this.filter.Page = 1

    switch (this.mos) {
      case 'for-sale':
        this.filter.Categories = [ListingCategory.ResidentialSale, ListingCategory.Rural, ListingCategory.ResidentialLand]
        this.filter.MethodsOfSale = [MethodOfSale.Sale, MethodOfSale.ForSale, MethodOfSale.Auction, MethodOfSale.Tender, MethodOfSale.EOI, MethodOfSale.Offers, MethodOfSale.Both]
        this.filter.Statuses = [ListingStatus.Current, ListingStatus.UnderContract]
        break
      case 'land-for-sale':
        this.filter.Categories = [ListingCategory.ResidentialLand]
        this.filter.MethodsOfSale = [MethodOfSale.Sale, MethodOfSale.ForSale, MethodOfSale.Auction, MethodOfSale.Tender, MethodOfSale.EOI, MethodOfSale.Offers, MethodOfSale.Both]
        this.filter.Statuses = [ListingStatus.Current, ListingStatus.UnderContract]
        break
      case 'for-rent':
        this.filter.Categories = [ListingCategory.ResidentialRental, ListingCategory.Rural]
        this.filter.MethodsOfSale = [MethodOfSale.Lease, MethodOfSale.Both]
        this.filter.Statuses = [ListingStatus.Current, ListingStatus.UnderContract]
        break
      case 'commercial-sale':
        this.filter.Categories = [ListingCategory.Commercial, ListingCategory.CommercialLand, ListingCategory.Business]
        this.filter.MethodsOfSale = [MethodOfSale.Sale, MethodOfSale.ForSale, MethodOfSale.Auction, MethodOfSale.Tender, MethodOfSale.EOI, MethodOfSale.Offers, MethodOfSale.Both]
        this.filter.Statuses = [ListingStatus.Current, ListingStatus.UnderContract]
        break
      case 'commercial-rent':
        this.filter.Categories = [ListingCategory.Commercial, ListingCategory.CommercialLand, ListingCategory.Business]
        this.filter.MethodsOfSale = [MethodOfSale.Lease, MethodOfSale.Both]
        this.filter.Statuses = [ListingStatus.Current, ListingStatus.UnderContract]
        break
      case 'sold':
        this.filter.Categories = []
        this.filter.Statuses = [ListingStatus.Sold]
        break
      default:
        this.filter.Categories = [ListingCategory.ResidentialSale, ListingCategory.Rural, ListingCategory.ResidentialLand]
        this.filter.MethodsOfSale = [MethodOfSale.Sale, MethodOfSale.ForSale, MethodOfSale.Auction, MethodOfSale.Tender, MethodOfSale.EOI, MethodOfSale.Offers, MethodOfSale.Both]
        this.filter.Statuses = [ListingStatus.Current, ListingStatus.UnderContract]
        break
    }

    this.emitFilter()
  }

  suburbsUpdated(selectedSuburbs: MultiSelectOption[]) {
    this.selectedSuburbs = selectedSuburbs

    // this.updateFilter();
  }

  private emitDebounce: number | undefined = undefined;

  emitFilter() {
    this.filter.SearchLevel = WebsiteLevel.Office
    this.filter.SearchGuid = Config.Website.Settings!.WebsiteId

    this.$emit('filter_updated', this.filter)
  }
}
